import { regionPasswordSuggestions } from '~/assets/data/config';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import ExclamationIcon from '~/components/design-system/icons/ExclamationIcon.vue';

interface IPasswordSuggestions {
  min?: number;
  max?: number;
  vulnerable?: boolean;
  spaces?: boolean;
  required?: requiredCharacters[];
}

type requiredCharacters = 'upper' | 'lower' | 'special' | 'number';

export default function () {
  const { $t } = useNuxtApp();
  const site = useSiteStore();
  const successGreen = 'primary-blue-500';

  //TODO: check if WMS passSuggest exists

  const passwordSuggestions: IPasswordSuggestions[] =
    regionPasswordSuggestions[site.getRegionCode];
  let finalSuggestions = [];
  if (passwordSuggestions.length > 0) {
    passwordSuggestions.forEach((ps) => {
      if (ps?.min)
        finalSuggestions.push({
          text: $t(`min-${ps.min}-char`),
          display: false,
          regex: [`^(.){${ps.min},}$`],
          icon: CheckCircleIcon,
          colour: successGreen,
        });

      if (ps?.max)
        finalSuggestions.push({
          text: $t(`max-${ps.max}-char`),
          display: false,
          regex: [`^(.){0,${ps.max}}$`],
          icon: CheckCircleIcon,
          colour: successGreen,
        });

      if (ps?.vulnerable)
        finalSuggestions.push({
          text: $t('password-is-vulnerable'),
          display: false,
          regex: ['(?=.*password)', 'i'],
          icon: ExclamationIcon,
          colour: 'gold-500',
        });

      if (ps?.spaces)
        finalSuggestions.push({
          text: $t('please-remove-spaces'),
          display: false,
          regex: ['(\\s)'],
          icon: XCircleIcon,
          colour: 'red-500',
        });

      if (ps?.required?.length > 0) {
        ps.required.forEach((req) => {
          switch (req) {
            case 'upper':
              finalSuggestions.push({
                text: $t('at-least-one-upper'),
                display: false,
                regex: ['([A-Z])'],
                icon: CheckCircleIcon,
                colour: successGreen,
              });
              break;
            case 'lower':
              finalSuggestions.push({
                text: $t('at-least-one-lower'),
                display: false,
                regex: ['([a-z])'],
                icon: CheckCircleIcon,
                colour: successGreen,
              });
              break;
            case 'number':
              finalSuggestions.push({
                text: $t('at-least-one-num'),
                display: false,
                regex: ['([0-9])'],
                icon: CheckCircleIcon,
                colour: successGreen,
              });
              break;
            case 'special':
              finalSuggestions.push({
                text: $t('at-least-one-special-char'),
                display: false,
                regex: ['[^\\w\\s]+|_+'],
                icon: CheckCircleIcon,
                colour: successGreen,
              });
              break;

            default:
              break;
          }
        });
      }
    });
  }
  return finalSuggestions;
}
